<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="registerForm">
      <b-form>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                rules="required|min:8"
                vid="confirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="New Password"
                    vid="confirmation"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->
          <!-- confirm password -->
          <b-col md="6">
            <b-form-group
              label-for="account-confirm-password"
              label="Confirm Password"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:confirmation"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-confirm-password"
                    v-model="confirmpassword"
                    :type="passwordFieldTypeRetype"
                    name="confirm-password"
                    placeholder="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              :disabled="!active"
              @click="openConfirmationModal"
            >
              <div
                v-if="spinner"
                class="spinner"
              >
                <b-spinner
                  small
                />
                <span class="sr-only">Loading...</span>
              </div>
              Save changes
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BSpinner,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newPasswordValue: '',
      confirmpassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      active: true,
      spinner: false,
      // validation
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    openConfirmationModal() {
      this.$swal({
        title: '<span class="font-weight-bolder">Save Password</span>',
        icon: 'warning',
        html:
            'You want to save changes in password!',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.sendPasswordUpdate()
        }
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    sendPasswordUpdate() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          await this.$axios.patch('user/update-password', { password: this.newPasswordValue }).then(() => {
            this.spinner = false
            this.active = true
            this.$swal('Your password updated successfully').then(result => {
              if (result.value) {
                this.newPasswordValue = ''
                this.confirmpassword = ''
              }
            })
          }).catch(error => {
            this.$swal(error.message)
            this.newPasswordValue = ''
            this.confirmpassword = ''
          })
        }
      })
    },
  },
}
</script>
