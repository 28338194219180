<template>
  <section>
    <b-card>
      <!-- form -->
      <b-form>
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                :src="userImgSrc ? userImgSrc : loggedInUser.image"
                rounded
                height="80"
                width="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="inputFileClicked"
            >
              Upload
            </b-button>
            <b-form-file
              id="userImageFile"
              v-model="userImage"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @change="uploadUserImage($event)"
            />
            <!--/ upload button -->
          </b-media-body>
        </b-media>
        <b-row class="mt-2">
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-username"
            >
              <b-form-input
                v-model="currentUser.name"
                placeholder="Username"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="loggedInUser.email"
                name="email"
                placeholder="Email"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Gender"
              label-for="account-gender"
            >
              <b-form-select
                v-model="currentUser.gender"
                :options="genderOptions"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Phone Number"
              label-for="account-phone-number"
            >
              <b-form-input
                v-model="loggedInUser.phone_number"
                name="phone-number"
                placeholder="Phone Number"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Date of Birth"
              label-for="account-date-of-birth"
            >
              <b-form-datepicker
                id="account-date-of-birth"
                v-model="currentUser.date_of_birth"
                placeholder="Please select your date of birth"
                class="mb-1"
                :date-disabled-fn="dateDisabled"
                :config="{ dateFormat: 'YYYY-MM-DD', disable:[{from:dateLimit,}], endDate:dateLimit}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="!active"
              @click.prevent="updateUserAccount"
            >
              <div
                v-if="spinner"
                class="spinner"
              >
                <b-spinner
                  small
                />
                <span class="sr-only">Loading...</span>
              </div>
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </section>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BImg,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BSpinner,
  BFormDatepicker,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { getFormDataFromJSON, showToast } from '@/common/global/functions'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BFormSelect,
    BLink,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateLimit: moment(Date.now()).format('YYYY-MM-DD'),
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      userImage: null,
      userImgSrc: null,
      uploadedUserImage: null,
      active: true,
      spinner: false,
      currentUser: {
        name: null,
        gender: null,
        date_of_birth: null,
        image: null,
      },
      genderOptions: [{
        value: null,
        text: 'Please select your gender',
        disabled: true,
      },
      {
        value: 1,
        text: 'Male',
      },
      {
        value: 2,
        text: 'Female',
      },
      {
        value: 3,
        text: 'Others',
      },
      ],
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters['user/getUser']
    },
  },
  watch: {
    loggedInUser: {
      handler(value) {
        this.currentUser = {
          name: value.name,
          gender: value.gender,
          date_of_birth: value.date_of_birth,
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return moment(date).format('YYYY-MM-DD') >= moment(Date.now()).format('YYYY-MM-DD')
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    async updateUserAccount() {
      this.spinner = true
      this.active = false
      this.$axios
        .patch('user', getFormDataFromJSON(this.currentUser))
        .then(({ data }) => {
          showToast('Account Settings', 'Account details updated successfully!')
          this.spinner = false
          this.active = true
          const { data: dataRes } = data || {}
          this.$store.dispatch('user/setUser', dataRes)
        }).catch(error => ({ error }))
    },
    inputFileClicked(event) {
      document.getElementById('userImageFile').click(event)
    },
    uploadUserImage(event) {
      const obj = event.target.files[0]
      this.userImgSrc = URL.createObjectURL(obj)
      this.currentUser.image = obj
    },
  },
}
</script>
